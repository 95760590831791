import { Action } from '@ngrx/store';

export enum ProgramDashboardActionTypes {
    LIST_PHASE = '[Program Dashboard] Program dashboard List phase',
    LIST_PHASE_SUCCESS = '[Program Dashboard] Program dashboard List phase success',
    ADD_PHASE = '[Program Dashboard] Program Dashboard add new phase',
    EDIT_PHASE = '[Program Dashboard] Program Dashboard edit phase',
    CREATE_BULK_TASK = '[Program Dashboard] Program Dashboard Create bulk tasks',
    CREATE_TASK = '[Program Dashboard]Create task',
    CREATE_TASK_SUCCESS = '[Program Dashboard]Create task Success',
    GET_PROJECTS = '[Program Dashboard]Get Projects',
    GET_PROJECTS_SUCCESS = '[Program Dashboard] Get Projects Success',
    GET_BUDGET_PROJECTS = '[Program Dashboard Budget]Get Projects',
    GET_BUDGET_PROJECTS_SUCCESS = '[Program Dashboard Budget] Get Projects Success',
    GET_PROJECT_DETAILS = '[Program Dashboard] Get Project Details',
    GET_PROJECT_DETAILS_SUCCESS = '[Program Dashboard] Get Project Details Success',
    GET_MEMBERS = '[Program Dashboard] Get Members',
    GET_MEMBERS_SUCCESS = '[Program Dashboard] Get Members Success',
    GET_TASKS_FOR_PROJECT = '[Program Dashboard] Get Tasks for Project',
    GET_TASKS_FOR_PROJECT_SUCCESS = '[Program Dashboard] Get Tasks for Project success',
    GET_PILLARS = '[Program Dashboard]Get Pillars',
    GET_PILLARS_SUCCESS = '[Program Dashboard]Get Pillars Success',
    ADD_NEW_PILLAR = '[Program Dashboard] Add New Pillar',
    EDIT_PILLAR = '[Program Dashboard] Edit Pillar',
    CREATE_TASK_FOR_PROJECT = '[Program Dashboard] Create task for project',
    GET_GANTT_CHART_DATA = '[Program Dashboard] Get Gantt Chart data',
    GET_GANTT_CHART_DATA_SUCCESS = '[Program Dashboard] Get Gantt Chart data success',
    CREATE_PROJECT = '[Program Dashboard]Create Project',
    GET_MEMBER_ROLES = '[Program Dashboard] Get Member Roles',
    GET_MEMBER_ROLES_SUCCESS = '[Program Dashboard] Get Member Roles success',
    GET_MEMBERS_LIST = '[Program Dashboard] Get Members list',
    GET_MEMBERS_LIST_SUCCESS = '[Program Dashboard] Get Members list Success',
    MANAGE_MEMBERS = '[Program Dashboard] Manage Members',
    MANAGE_MEMBERS_SUCCESS = '[Program Dashboard] Manage Members Success',
    EDIT_TASK_IN_PROJECT = '[Program Dashboard] Edit Task In Project',
    EDIT_PROJECT = '[Program Dashboard] Edit Project',
    ADD_STEP = '[Program Dashboard] Add Step',
    EDIT_STEP = '[Program Dashboard] Edit Step',
    LIST_STEP = '[Program Dashboard] List step',
    LIST_STEP_SUCCESS = '[Program Dashboard] List step success',
    LIST_STEP_BY_ID = '[Program Dashboard] List step by ID',
    LIST_STEP_BY_ID_SUCCESS = '[Program Dashboard] List step by ID Success',
    GET_FINANCIAL_INFO = '[Program Dashboard] Get Financial Information',
    GET_FINANCIAL_INFO_SUCCESS = '[Program Dashboard] Get Financial Information Success',
    ADD_FUND_RELEASE_ITEM = '[Program Dashboard] Add Fund Release Item',
    ADD_FUND_UTILISED_ITEM = '[Program Dashboard] Add Fund Utilised Item',
    DELETE_INSTALLMENT_ITEM = '[Program Dashboard] Delete Installment Item',
    UPDATE_PROJECT_COST = '[Program Dashboard] Update Project Cost',
    UPDATE_CITIIS_GRANT = '[Program Dashboard] Update Citiis Grant',
    GET_ALL_COMPLAINCES = '[Program Dashboard] Get All Complainces',
    GET_ALL_COMPLAINCES_SUCCESS = '[Program Dashboard] Get All Complainces Success',
    CREATE_COMPLIANCE = '[Program Dashboard] Create Compliance',
    GET_RISKS_FOR_PROJECT = 'GET_RISKS_FOR_PROJECT',
    GET_RISKS_FOR_PROJECT_SUCCESS = 'GET_RISKS_FOR_PROJECT_SUCCESS',
    GET_OPPORTUNITIES_FOR_PROJECT = "GET_OPPORTUNITIES_FOR_PROJECT",
    GET_OPPORTUNITIES_FOR_PROJECT_SUCCESS = "GET_OPPORTUNITIES_FOR_PROJECT_SUCCESS",
    CREATE_OPPORTUNITY_FOR_PROJECT = "CREATE_OPPORTUNITY_FOR_PROJECT",
    CREATE_RISK_FOR_PROJECT = "CREATE_RISK_FOR_PROJECT",
    GET_PHASES = "GET_PHASES",
    GET_PHASES_SUCCESS = "GET_PHASES_SUCCESS",
    GET_RISK_INFO = '[Program Dashboard] Program Dashboard get risk details',
    GET_RISK_DETAILS_SUCCESS = '[Program Dashboard] Program Dashboard get risk details success',
    GET_OPPORTUNITY_DETAIL = "GET_OPPORTUNITY_DETAIL",
    GET_OPPORTUNITY_SUCCESS = "GET_OPPORTUNITY_SUCCESS",
    GET_OPPORTUNITY_DETAIL_SUCCESS = "GET_OPPORTUNITY_DETAIL_SUCCESS",
    EDIT_OPPORTUNITY_FOR_PROJECT = "EDIT_OPPORTUNITY_FOR_PROJECT",
    EDIT_RISK_FOR_PROJECT = "EDIT_RISK_FOR_PROJECT",
    GET_INSTALLMENTS = "[Program Dashboard] Get Installments",
    GET_INSTALLMENTS_SUCCESS = "[Program Dashboard] Get Installments Success",
    ADD_RISKS_FOR_PROJECT = "[Program Dashboard] Add Risks For Project",
    ADD_RISKS_FOR_PROJECT_SUCCESS = "[Program Dashboard] Add Risks For Project Success",
    GET_RISK_HISTORY = "[Program Dashboard] Get Risk History",
    GET_RISK_HISTORY_SUCCESS = "[Program Dashboard] Get Risk History Success",
    ADD_EDIT_INSTALLMENT = "[Program Dashboard] Add Edit Installments",
    CLEAR_ACTION = "[Program Dashboard] Clear Action Start",
    UPDATE_FUND_RELEASE_ITEM = "[Program Dashboard] Update Release item",
    GET_MY_OPEN_COMMENT = "[Program Dashboard] Get My Open Comment",
    GET_MY_OPEN_COMMENT_SUCCESS = "[Program Dashboard] Get My Open Comment Success",
    SAVE_OPEN_COMMENT = "[Program Dashboard] Save Open Comment",
    SAVE_OPEN_COMMENT_SUCCESS = "[Program Dashboard] Save Open Comment Success",
    GET_COMMENTS_HISTORY_LOG = "[Program Dashboard] Get Comments History Log",
    GET_COMMENTS_HISTORY_LOG_SUCCESS = "[Program Dashboard] Get Comments History Log Success",
    GET_COMMENTED_USERS = "[Program Dashboard] Get Commented Users",
    GET_COMMENTED_USERS_SUCCESS = "[Program Dashboard] Get Commented Users Success",
    GET_OTHER_USERS_OPEN_COMMENT = "[Program Dashboard] Get Other Users Open Comment",
    GET_OTHER_USERS_OPEN_COMMENT_SUCCESS = "[Program Dashboard] Get Other Users Open Comment Success",
    EDIT_OVERALL_PROGESS_DETAILS = "[Program Dashboard] Edit Overall Progress Details",
    GET_OVERALL_PROGESS_DETAILS = "[Program Dashboard] Get Overall Progress Details",
    GET_PROJECT_ACTIVITY_LOG = "[Program Dashboard] Get Project Activity Log",
    GET_PROJECT_ACTIVITY_LOG_SUCCESS = "[Program Dashboard] Get Project Activity Log Success",
    EDIT_TASK_STEP_PILLAR = '[Program Dashboard] Edit Task Pillar Step',
    UPDATE_FUND_UTILISE_ITEM = '[Program Dashbard] Update Fund utilised item',
    GET_OPPORTUNITY_HISTORY = "[Program Dashboard] Get OPPORTUNITY History",
    GET_OPPORTUNITY_HISTORY_SUCCESS = "[Program Dashboard] Get OPPORTUNITY History Success",
    GET_PROJECT_PHASES = "[Project Dashboard] Get project Phase",
    GET_PROJECT_PHASES_SUCCESS = "[Project Dashboard] Get project Phase Success",
    ADD_PROJECT_PHASE = "[Project Dashboard] Add project Phase",
    GET_PROJECT_STATES = "[Project Dashboard] Get Project States",
    GET_PROJECT_STATES_SUCCESS = "[Project Dashboard] Get Project States Success",
    GET_DASHBOARD_INFO = '[Project Dashboard] Get Dashboard Info',
    GET_DASHBOARD_INFO_SUCCESS = '[Project Dashboard] Get Dashboard Info Success',
    CREATE_BULK_COMPLIANCE_TASK = '[Project Dashboard] Create Bulk Compliance Task',
    GET_PROJECT_FINANCIAL_INFO = '[Project Dashboard] Get Project Financial Info',
    GET_PROJECT_FINANCIAL_INFO_SUCCESS = '[Project Dashboard] Get Project Financial Info Success',
    GET_L1_INDICATORS = '[Project Dashboard] Get L1 Indicators',
    GET_L1_INDICATORS_SUCCESS = '[Project Dashboard] Get L1 Indicators Success',
    GET_KC_INDICATORS = '[Project Dashboard] Get KC Indicators',
    GET_KC_INDICATORS_SUCCESS = '[Project Dashboard] Get KC Indicators Success',
    GET_ES_INDICATORS = '[Project Dashboard] Get ES Indicators',
    GET_ES_INDICATORS_SUCCESS = '[Project Dashboard] Get ES Indicators Success',
    GET_PLF_INDICATORS = '[Project Dashboard] Get PLF Indicators',
    GET_PLF_INDICATORS_SUCCESS = '[Project Dashboard] Get PLF Indicators Success',
    GET_INNOVATION_MATRIX = '[Project Dashboard] Get Innovatioon Matrix',
    GET_INNOVATION_MATRIX_SUCCESS = '[Project Dashboard] Get Innovatioon Matrix Success',
    UPDATE_INDICATOR = '[Project Dashboard] UPDATE INDICATOR VALUES',
    UPDATE_INDICATOR_SUCCESS = '[Project Dashboard] UPDATE INDICATOR VALUES SUCCESS',
    GET_CITIES = '[Project Dashboard] Get Cities',
    GET_CITIES_SUCCESS = '[Project Dashboard] Get Cities Success',
    GET_AGGREGATED_VALUES = '[Project Dashboard] Get Aggregated Values',
    GET_AGGREGATED_VALUES_SUCCESS = '[Project Dashboard] Get Aggregated Values Success',
    GET_SECTORAL_INDICATORS = '[Project Dashboard] Get SECTORAL Indicators',
    GET_SECTORAL_INDICATORS_SUCCESS = '[Project Dashboard] Get SECTORAL Indicators Success',
    GET_PROJECT_TASK_INFO = '[Project Dashboard] Get Project Task Info',
    GET_PROJECT_TASK_INFO_SUCCESS = '[Project Dashboard] Get Project Task Info Success',
    BULK_TASK_UPLOAD = "BULK_TASK_UPLOAD",
    BULK_TASK_UPLOAD_SUCCESS = "BULK_TASK_UPLOAD_SUCCESS",
    GET_E_S_INDICATORS_LIST = "GET_E_S_INDICATORS_LIST",
    GET_E_S_INDICATORS_LIST_SUCCESS = "GET_E_S_INDICATORS_LIST_SUCCESS",
    GET_FAV_LIST = "Get Fevorite List",

    GET_PROCUREMENT_PROJECTS = '[Program Dashboard PROCUREMENT]Get Projects',
    GET_PROCUREMENT_PROJECTS_SUCCESS = '[Program Dashboard PROCUREMENT] Get Projects Success',
}

export class CreateTask implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_TASK;
    constructor(public prjId, public payload: any) { }
}

export class CreateProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_PROJECT;
    constructor(public payload: any) { }
}

export class GetProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECTS;
    constructor(public params?: any) { }
}

export class GetProjectsSucess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECTS_SUCCESS;
    constructor(public response: any) { }
}

export class GetBudgetProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_BUDGET_PROJECTS;
    constructor(public role?:any,public params?: any) { }
}

export class GetBudgetProjectsSucess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_BUDGET_PROJECTS_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectDetail implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_DETAILS;
    constructor(public id: any) { }
}

export class GetProjectDetailSucess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_DETAILS_SUCCESS;
    constructor(public response: any) { }
}

export class CreateTaskSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_TASK_SUCCESS;
    constructor(public response: any) { }
}

export class GetMemberRoles implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBER_ROLES;
    constructor(public prjId: any) { }
}

export class GetMemberRolesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBER_ROLES_SUCCESS;
    constructor(public memberRoles: any) { }
}

export class GetMembers implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBERS;
    constructor(public role: any) { }
}

export class GetMembersSucess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBERS_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectPillars implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PILLARS;
    constructor() { }
}

export class GetProjectPillarSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PILLARS_SUCCESS;
    constructor(public pillars: any) { }
}

export class EditPillar implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_PILLAR;
    constructor(public pillarId: any, public pillarName: any) { }
}

export class GetTasksForProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_TASKS_FOR_PROJECT;
    constructor(public projectId: any) { }
}

export class GetTasksForProjectsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_TASKS_FOR_PROJECT_SUCCESS;
    constructor(public response: any) { }
}
export class GetRisksForProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISKS_FOR_PROJECT;
    constructor(public projectId: any) { }
}

export class GetRisksForProjectsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISKS_FOR_PROJECT_SUCCESS;
    constructor(public response: any) { }
}

export class GetOpportunitiesForProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITIES_FOR_PROJECT;
    constructor(public projectId: any) { }
}
export class GetOpportunitiesForProjectsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITIES_FOR_PROJECT_SUCCESS;
    constructor(public response: any) { }
}

export class AddNewPillar implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_NEW_PILLAR;
    constructor(public pillar: any) { }
}

export class CreateTaskForProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_TASK_FOR_PROJECT;
    constructor(public projectId, public payload: any, public complianceType?: boolean) { }
}

export class CreateRisksForProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_RISK_FOR_PROJECT;
    constructor(public projectId, public payload: any) { }
}

export class EditRiskForProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_RISK_FOR_PROJECT;
    constructor(public projectId, public riskId, public payload: any) { }
}

export class CreateOpportunityForProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_OPPORTUNITY_FOR_PROJECT;
    constructor(public projectId, public payload: any) { }
}

export class EditOpportunityForProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_OPPORTUNITY_FOR_PROJECT;
    constructor(public projectId, public opportunityId, public payload: any) { }
}
export class GetGanttChartData implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_GANTT_CHART_DATA;
    constructor(public projectId: any) { }
}

export class GetPhases implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PHASES;
    constructor() { }
}

export class GetPhasesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PHASES_SUCCESS;
    constructor(public response: any) { }
}

export class GetGanttChartDataSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_GANTT_CHART_DATA_SUCCESS;
    constructor(public response: any) { }
}

export class EditTaskInProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_TASK_IN_PROJECT;
    constructor(public projectId, public taskId, public payload: any) { }
}

export class GetMembersList implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBERS_LIST;
    constructor(public projectID: any) { }
}

export class GetMembersListSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MEMBERS_LIST_SUCCESS;
    constructor(public members: any) { }
}

export class ManageMembers implements Action {
    readonly type: string = ProgramDashboardActionTypes.MANAGE_MEMBERS;
    constructor(public id: string, public userId: string) { }
}

export class ManageMembersSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.MANAGE_MEMBERS_SUCCESS;
    constructor(public response: any) { }
}

export class EditProject implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_PROJECT;
    constructor(public projectId: any, public payload: any) { }
}

export class AddNewStep implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_STEP;
    constructor(public step: any) { }
}

export class GetProjectSteps implements Action {
    readonly type: string = ProgramDashboardActionTypes.LIST_STEP;
    constructor() { }
}

export class GetProjectStepsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.LIST_STEP_SUCCESS;
    constructor(public steps: any) { }
}

export class EditSteps implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_STEP;
    constructor(public stepId: any, public stepName: any) { }
}

export class GetFinancialInfo implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_FINANCIAL_INFO;
    constructor(public projectId: any) { }
}

export class GetFinancialInfoSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_FINANCIAL_INFO_SUCCESS;
    constructor(public info: any) { }
}

export class AddFundReleaseItem implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_FUND_RELEASE_ITEM;
    constructor(public projectId: any, public payload: any) { }
}

export class AddFundUtilisedItem implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_FUND_UTILISED_ITEM;
    constructor(public projectId: any, public payload: any) { }
}

export class DeleteInstallmentItem implements Action {
    readonly type: string = ProgramDashboardActionTypes.DELETE_INSTALLMENT_ITEM;
    constructor(public projectId: any, public payload: any, public itemType: any) { }
}


export class GetProjectPhase implements Action {
    readonly type: string = ProgramDashboardActionTypes.LIST_PHASE;
    constructor() { }
}

export class GetProjectPhaseSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.LIST_PHASE_SUCCESS;
    constructor(public phases: any) { }
}

export class AddNewPhase implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_PHASE;
    constructor(public phase: any, public colorCode: any) { }
}

export class EditPhase implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_PHASE;
    constructor(public phaseId: any, public phaseName: any, public colorCode: any) { }
}

export class UpdateProjectCost implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_PROJECT_COST;
    constructor(public projectId: any, public payload: any) { }
}

export class UpdateCitiisGrant implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_CITIIS_GRANT;
    constructor(public projectId: any, public payload: any) { }
}

export class GetAllComplainces implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_ALL_COMPLAINCES;
    constructor(public projectId: any) { }
}

export class GetAllComplaincesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_ALL_COMPLAINCES_SUCCESS;
    constructor(public response: any) { }
}

export class CreateCompliance implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_COMPLIANCE;
    constructor(public projectId: any, public payload: any) { }
}

export class GetRiskDetails implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISK_INFO;
    constructor(public projectId: any, public riskId: any) { }
}

export class GetRiskInfoSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISK_DETAILS_SUCCESS;
    constructor(public response: any) { }
}


export class CreateBulkTask implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_BULK_TASK;
    constructor(public projectId: any, public payload: any) { }
}

export class GetOpportunityDetail implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITY_DETAIL;
    constructor(public projectId: any, public opportunityId: any) { }
}

export class GetOpportunityDetailSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITY_DETAIL_SUCCESS;
    constructor(public response: any) { }
}

export class AddRiskSForProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_RISKS_FOR_PROJECT;
    constructor(public projectId: string, public payload: any) { }
}

export class AddRiskSForProjectsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_RISKS_FOR_PROJECT_SUCCESS;
    constructor(public response: any) { }
}

export class GetRiskHistory implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISK_HISTORY;
    constructor(public projectId: string, public riskId: string) { }
}

export class GetRiskHistorySuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_RISK_HISTORY_SUCCESS;
    constructor(public response: any) { }
}
export class AddEditInstallment implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_EDIT_INSTALLMENT;
    constructor(public projectId: any, public payload: any, public installmentType: any) { }
}

export class GetInstallments implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_INSTALLMENTS;
    constructor(public projectId: any, public installmentType: any) { }
}

export class GetInstallmentsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_INSTALLMENTS_SUCCESS;
    constructor(public installments: any) { }
}

export class ClearAction implements Action {
    readonly type: string = ProgramDashboardActionTypes.CLEAR_ACTION;
    constructor() { }
}

export class UpdateFundReleaseItem implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_FUND_RELEASE_ITEM;
    constructor(public projectId: any, public payload: any) { }
}

export class UpdateFundUtiliseItem implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_FUND_UTILISE_ITEM;
    constructor(public projectId: any, public payload: any) { }
}

export class GetMyOpenComment implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MY_OPEN_COMMENT;
    constructor(public projectId: string) { }
}

export class GetMyOpenCommentSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_MY_OPEN_COMMENT_SUCCESS;
    constructor(public response: any) { }
}

export class SaveOpenComment implements Action {
    readonly type: string = ProgramDashboardActionTypes.SAVE_OPEN_COMMENT;
    constructor(public projectId: string, public payload: any) { }
}

export class SaveOpenCommentSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.SAVE_OPEN_COMMENT_SUCCESS;
    constructor(public response: any) { }
}

export class GetCommentsHistoryLog implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_COMMENTS_HISTORY_LOG;
    constructor(public projectId: string) { }
}

export class GetCommentsHistoryLogSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_COMMENTS_HISTORY_LOG_SUCCESS;
    constructor(public response: any) { }
}

export class GetCommentedUsers implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_COMMENTED_USERS;
    constructor(public projectId: string) { }
}

export class GetCommentedUsersSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_COMMENTED_USERS_SUCCESS;
    constructor(public response: any) { }
}

export class GetOtherUsersOpenComment implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OTHER_USERS_OPEN_COMMENT;
    constructor(public projectId: string, public userId: string) { }
}

export class GetOtherUsersOpenCommentSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OTHER_USERS_OPEN_COMMENT_SUCCESS;
    constructor(public response: any) { }
}

export class EditOverallProgressDetails implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_OVERALL_PROGESS_DETAILS;
    constructor(public projectId, public payload: any) { }
}
export class GetOverallProgressDetails implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OVERALL_PROGESS_DETAILS;
    constructor(public projectId) { }
}

export class GetProjectActivityLog implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_ACTIVITY_LOG;
    constructor(public projectId: any) { }
}

export class GetProjectActivityLogSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_ACTIVITY_LOG_SUCCESS;
    constructor(public response: any) { }
}

export class EditTaskStepPillar implements Action {
    readonly type: string = ProgramDashboardActionTypes.EDIT_TASK_STEP_PILLAR;
    constructor(public projectId, public taskId, public payload: any) { }
}

export class GetOpportunityHistory implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITY_HISTORY;
    constructor(public projectId: string, public opportunityId: string) { }
}

export class GetOpportunityHistorySuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_OPPORTUNITY_HISTORY_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectPhases implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_PHASES;
    constructor(public projectId: any) { }
}

export class GetProjectPhasesList implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_PHASES;
    constructor(public projectId: any) { }
}

export class GetProjectPhasesListSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_PHASES_SUCCESS;
    constructor(public projectPhases: any) { }
}

export class GetProjectPhasesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_PHASES_SUCCESS;
    constructor(public projectPhases: any) { }
}


export class AddProjectPhase implements Action {
    readonly type: string = ProgramDashboardActionTypes.ADD_PROJECT_PHASE;
    constructor(public projectId: any, public payload: any) { }
}

export class GetProjectStates implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_STATES;
    constructor() { }
}

export class GetProjectStatesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_STATES_SUCCESS;
    constructor(public states: any) { }
}

export class GetDashboardInfo implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_DASHBOARD_INFO;
    constructor() { }
}

export class GetDashboardInfoSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_DASHBOARD_INFO_SUCCESS;
    constructor(public response: any) { }
}

export class CreateBulkComplianceTask implements Action {
    readonly type: string = ProgramDashboardActionTypes.CREATE_BULK_COMPLIANCE_TASK;
    constructor(public projectId: any, public payload: any) { }
}

export class GetProjectFinancialInfo implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_FINANCIAL_INFO;
    constructor() { }
}

export class GetProjectFinancialInfoSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_FINANCIAL_INFO_SUCCESS;
    constructor(public information: any) { }
}

export class GetL1Indicators implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_L1_INDICATORS;
    constructor(public level: any, public payload: any) { }
}

export class GetL1IndicatorsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_L1_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetKCIndicators implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_KC_INDICATORS;
    constructor(public component: any, public payload: any) { }
}

export class GetKCIndicatorsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_KC_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetESIndicators implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_ES_INDICATORS;
    constructor(public component: any, public payload: any) { }
}

export class GetESIndicatorsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_ES_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetPLFIndicators implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PLF_INDICATORS;
    constructor(public component: any, public payload: any) { }
}

export class GetPLFIndicatorsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PLF_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetInnovationMatrix implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_INNOVATION_MATRIX;
    constructor(public payload: any) { }
}

export class GetInnovationMatrixSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_INNOVATION_MATRIX_SUCCESS;
    constructor(public response: any) { }
}

export class UpdateIndicatorValues implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_INDICATOR;
    constructor(public payload: any) { }
}

export class UpdateIndicatorValuesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.UPDATE_INDICATOR_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectCities implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_CITIES;
    constructor(public id: any, public payload: any) { }
}

export class GetProjectCitiesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_CITIES_SUCCESS;
    constructor(public response: any) { }
}

export class AggregatedValues implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_AGGREGATED_VALUES;
    constructor(public id: any, public payload: any) { }
}

export class AggregatedValuesSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_AGGREGATED_VALUES_SUCCESS;
    constructor(public response: any) { }
}

export class GetSectoralIndicators implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_SECTORAL_INDICATORS;
    constructor(public payload: any) { }
}

export class GetSectoralIndicatorsSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_SECTORAL_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectTaskInfo implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_TASK_INFO;
    constructor() { }
}

export class GetProjectTaskInfoSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROJECT_TASK_INFO_SUCCESS;
    constructor(public response: any) { }
}

export class BulkTasksUpload implements Action {
    readonly type: string = ProgramDashboardActionTypes.BULK_TASK_UPLOAD;
    constructor(public payload: any,public projId: any) { }
}

export class BulkTasksUploadSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.BULK_TASK_UPLOAD_SUCCESS;
    constructor(public response: any) { }
}

export class GetESIndicatorsList implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_E_S_INDICATORS_LIST;
    constructor(public id: any) { }
}

export class GetESIndicatorsListSuccess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_E_S_INDICATORS_LIST_SUCCESS;
    constructor(public response: any) { }
}

export class GetFavList implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_FAV_LIST;
    constructor() { }
}

export class GetProcurementProjects implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROCUREMENT_PROJECTS;
    constructor(public role?:any,public params?: any) { }
}

export class GetProcurementProjectsSucess implements Action {
    readonly type: string = ProgramDashboardActionTypes.GET_PROCUREMENT_PROJECTS_SUCCESS;
    constructor(public response: any) { }
}
