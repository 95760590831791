import { API } from './services/Api.service';
import { ViewListService } from './services/list-grid-view.service'
import { newMessageTagService } from './services/messageTags.service'
import { ProfilePictureService } from './services/profilePicture.service'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerURLInterceptor } from './services/auth.interceptor';
import { AuthGaurd } from './guards/auth.guard';
import { CommonService } from './services/tasks.services';
import { LoginGuard } from './guards/login.guard';
import { SocketService } from './services/socket.service';

import { ErrorHandler } from '@angular/core';

import { SentryErrorHandler } from './services/sentry-error-handler'
import { ContractManagementService } from './features/contract-management/contract-management.service';
import { ToolbarService, ExcelExportService, SelectionService, PdfExportService } from '@syncfusion/ej2-angular-gantt';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

export const APP_PROVIDERS = [
    API,
    ContractManagementService,
    ViewListService,
    newMessageTagService,
    ProfilePictureService,
    AuthGaurd,
    LoginGuard,
    SocketService,
    ToolbarService,
    ExcelExportService,
    SelectionService,
    PdfExportService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ServerURLInterceptor,
        multi: true
    },
    // {
    //     provide: ErrorHandler,
    //     useClass: SentryErrorHandler,
    // },
    CommonService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
];
