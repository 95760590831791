import { Component, OnInit, ChangeDetectorRef, Inject, ErrorHandler } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from "rxjs/operators";
import { AppState } from './app.reducer';
import { NgxPermissionsService } from 'ngx-permissions';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Logout, GetUserRoles, GetUserCapabilites, AuthActionTypes, ErrorAction } from './login/store/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { LoaderHide } from './features/loader/store/loader.actions';
import { ProfilePictureService } from './services/profilePicture.service';
import { GetWebNotifications, UpdateWebNotificationSeen } from 'src/app/features/home/notifications/store/notifications.actions';
import { Subject } from "rxjs";
import { SocketService } from './services/socket.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { type } from 'os';
import { GetWebNotificationCount } from './features/home/notifications/store/notifications.actions'
import { GetDocumentById, GetAsOfVersionStartAction } from './features/home/resources/_store/resources.actions'
import { GetTaskDetails } from './features/home/tasks/store/tasks.actions';
import { Location } from '@angular/common';
import {environment} from '../environments/environment'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cmp-angular';
  options = {
    timeOut: 4000,
    showProgressBar: false,
    pauseOnHover: true,
    clickToClose: true,
    preventDuplicates: true,
    maxStack: 1,
  };
  isLoggedIn;
  userName;
  role;
  profilePic;
  userId: any;
  notificationWebList: Array<any> = [];
  stopper$: Subject<any> = new Subject();
  notificationCount;
  unreadNotificationCount;
  unreadMessageCount;
  token: any;
  userDetails: any;
  isIE: boolean;
  bannerShow:any;
  constructor(
    private store: Store<AppState>,
    private ngxPermissionService: NgxPermissionsService,
    private loc: Location,
    private router: Router,
    private notificationService: NotificationsService,
    private action$: Actions,
    private profile: ProfilePictureService,
    private cdr: ChangeDetectorRef,
    private socketService: SocketService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(ErrorHandler) private sentry
  ) {
    this.isIE = this.isIEBrowser();
    console.log("isIE?1", this.isIE);

    iconRegistry.addSvgIcon(
      'bell-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/bell_icon.svg'));



    iconRegistry.addSvgIcon(
      'help',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/help.svg'));

    this.store.select(state => state.auth).subscribe(auth => {
      this.isLoggedIn = auth.isLoggedIn;
      this.token = auth.accessToken;
      // tslint:disable-next-line:max-line-length
      this.userDetails = (auth.user || {}).details;
      this.userName = (auth.user.details || {}).firstName + ' ' + ((auth.user.details || {}).middleName || '') + ' ' + (auth.user.details || {}).lastName;
      this.role = auth.user.role;
      this.userId = (auth.user.details || {})._id;
      if (this.role) {
        if (this.role.length > 1) {
          this.role = ""
        }
      }
      const capabilities =
        (auth.user.capabilities || []).map(cap => cap.capabilities)
          .reduce((acc, capabilityArray) => {
            return acc.concat(capabilityArray);
          }, []);
      this.ngxPermissionService.loadPermissions([...capabilities, 'all'] || []);
      console.log(this.userDetails);
      if(this.userDetails != undefined){
        this.portalBtnAllow();
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((res) => {
      this.bannerShow=localStorage.getItem('isChallengeBanner')
    })

    // const agent = window.navigator.userAgent.toLowerCase();
    //  this.isIE = window.navigator.userAgent.toLowerCase().includes('trident');

    // console.log("isInternetExplorer",isIE);
    console.log("isIE?2", this.isIE);
    this.profile.CurrentPicture.subscribe(picture => {
      this.profilePic = picture
      this.cdr.detectChanges();
    });

    this.action$.pipe(
      ofType(AuthActionTypes.ERROR),
      tap((action: ErrorAction) => {
        // console.log(action.error.error, "error");
        this.store.dispatch(new LoaderHide())

        const error = (action.error || {}).error;
        // this.sentry.handleError(error);

        let errorMsg;
        if (error && error.errors && error.errors.length > 0) {
          errorMsg = error.errors[0].error;
        }
        if (errorMsg && errorMsg.length > 0) {
          this.notificationService.error(errorMsg);
        }
      }),
    ).subscribe();


    // this.socketService.getData().subscribe((data => {
    //   // console.log(data)
    //   if (typeof data === 'number') {
    //     this.notificationCount = data;
    //   }
    // }));
    this.store.dispatch(new GetWebNotificationCount())
    this.store.select(state => state.notifications.notificationCount).subscribe(res => {
      if (typeof res === 'number')
        this.notificationCount = res
    }
    )
  }

  gotoNotificationDetails(doc) {
    if (doc.read == false) {
      this.store.dispatch(new UpdateWebNotificationSeen(doc._id));
    }

    switch (doc.notificationType) {
      case 'DOCUMENTS':
        if (this.router.url.split('/')[this.router.url.split('/').length - 2] == 'doc'){
          this.store.dispatch(new GetDocumentById(doc.docId, true))
          this.store.dispatch(new GetAsOfVersionStartAction(doc.docId));}
        else
          this.router.navigate(['home/resources/doc/' + doc.docId]);
        break;

      case 'TASK':
        if (this.router.url.split('/').includes('tasks')) {
          this.store.dispatch(new GetTaskDetails(doc.taskId));
        }
        this.router.navigate(['home/tasks/' + doc.taskId]);
        break;

      case 'MESSAGES':
        this.router.navigate(['/home/messages/my-messages']);
        break;
      
      case 'SYSTEM_FOLDER':
        this.router.navigate(['home/resources/me/citis/' +doc.folderId]);
        break;

      case 'CONTRACT':
        if(doc.title.includes('invoice')){
          this.router.navigate([`/home/contract-management/${doc.contractId}/invoices`])
        }
        else{
          this.router.navigate([`/home/contract-management/${doc.contractId}/contract-details`])
        }
        break
      default:
        break;
    }
  }

  editProfile() {
    this.router.navigate(['home/profile']);
  }

  listTags() {
    this.router.navigate(['home/tags']);
  }

  userGroups() {
    this.router.navigate(['home/user-groups']);
  }

  privateMembers() {
    this.router.navigate(['home/private-members']);
  }

  templates() {
    this.router.navigate(['home/templates'])
  }
  configuration() {
    this.router.navigate(['home/configurations'])
  }
  notifications() {
    this.router.navigate(['home/notifications'])
  }

  contractManagement() {
    this.router.navigate(['home/contract-management']);
  }

  entityManagement(){
    this.router.navigate(['home/entity-management']);
  }
  logout() {
    this.store.dispatch(new Logout(this.userId, this.token));
  }

  setDefaultPic() {
    this.profilePic = "assets/images/default-profile-img.svg";
  }

  webNotifications() {
    this.router.navigate(['home/web-notifications/list-notifications']);
  }

  getWebNotifications() {
    this.store.dispatch(new GetWebNotifications({ limit: 2, page: 1 }));
    this.store.select(state => state.notifications.notifWebList).pipe(takeUntil(this.stopper$)).subscribe((docsList: any) => {
      if (Object.keys(docsList).length) {
        this.notificationWebList = docsList.docs;
      }
    });
  }

  openLink() {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSfwQPSXCsv44ZrexEqPRK7gzRmibPTgQsieiO8Q1p5sXjUc-A/viewform', '_blank');
  }

  openHelpDeskLink() {
    window.open
      (`https://citiis.niua.org/content/help-desk?fname=${this.userDetails.firstName}&mname=${this.userDetails.middleName}&lname=${this.userDetails.lastName}&email=${this.userDetails.email}&ref=${location.host + this.router.url}`);
  }

  isIEBrowser() {
    console.log("inside isIEBrowser")
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11

    console.log("msie", msie)
    console.log("trident", trident)
    return (msie > 0 || trident > 0);
  }

  updatePortal(val:any) {
    const url = window.location.href.split('/');
    if (url[3] !== val && val == 'v2'){
      window.open(`${environment.download_template}/v2`,"_self");
    } 
  }

  getHostname() : string {
    return window.location.hostname;
  }

  portalBtnAllow(){
    const url = window.location.href.split('/');      
    if((url[3]== 'v1' || url[3]== '#')  && this.userDetails.cities_1_flag == false && this.userDetails.cities_2_flag == true ){
      window.open(`${environment.download_template}/v2`,"_self");
    }
    if(url[3]== '#'){
      window.open(`${environment.download_template}/v1`,"_self");
    }
  }

  getciitis1(val){
  const url = window.location.href.split('/');  
  if((url[3]== 'v1'|| url[3]== '#') && val == true){
    return true
  }

  }

  getciitis2(val){
    const url = window.location.href.split('/');
    if((url[3]== 'v1'|| url[3]== '#')  && val == false){
      return true
    }
  }

  isShowButton(value1: boolean, value2: boolean): boolean {
    return !(value1 && value2);
  }

}
